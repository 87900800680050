<template>
  <v-container fluid class="my-4">
    <v-expansion-panels v-model="panels" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Project Defintion</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Project Number"
                v-model="projectInfo.number"
              />
              <v-text-field
                v-model="projectInfo.manager"
                label="Project Manager"
              />
              <v-text-field
                label="Load Case"
                v-model="projectInfo.loadCase"
              />
              <v-menu
                v-model="menuDesignDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="designDate"
                    label="Design Date"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="projectInfo.designDate"
                  no-title
                  @input="menuDesignDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Project Name"
                v-model="projectInfo.name"
              />
              <v-text-field
                v-model="projectInfo.creator"
                label="Project Creator"
              />
              <v-text-field
                label="Revision Number"
                v-model="projectInfo.revision"
              />
              <v-menu
                v-model="menuRevisionDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="revisionDate"
                    label="Revision Date"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="projectInfo.revisionDate"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="ambient !== null">
        <v-expansion-panel-header>
          <h2>Ambient Conditions</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-numeric-text-field-unit
                v-model="ambient.T"
                :settings="settings.temperature"
                :rules="[requiredRule]"
                @change="getAmbientComposition"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-numeric-text-field-unit
                v-model="ambient.p"
                :settings="settings.pressure"
                :rules="[requiredRule]"
                @change="getAmbientComposition"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-numeric-text-field-unit
                v-model="ambient.phi"
                :settings="settings.phi"
                @change="getAmbientComposition"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              v-for="s in ambient.dry.species"
              :key="s.publicId"
            >
              <v-text-field
                v-if="s.formula !== 'H2O'"
                :value="getFraction(s)"
                :suffix="inputFraction.unit"
                @change="changeFraction($event, s)"
              >
                <template slot="label">
                  <span v-html="htmlFormula(s.formula)"></span
                ></template>
                <v-menu
                  slot="append"
                  transition="slide-y-transition"
                  bottom
                  close-on-content-click
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="inputFractions.length > 1"
                      v-bind="attrs"
                      v-on="on"
                      class="switch-unit-button"
                    >
                      mdi-menu-down
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(fraction, index) in inputFractions"
                      :key="index"
                      @click="changeInputFraction(fraction)"
                    >
                      <v-list-item-title>{{
                        fraction.unit
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="6" md="6">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center"></th>
                      <th class="text-center">
                        Mass Fractions [%mass]
                      </th>
                      <th class="text-center">
                        Volume Fractions [%vol]
                      </th>
                      <th class="text-center">
                        Mole Fractions [%mol]
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(s, i) in ambient.wet.species"
                      :key="i"
                    >
                      <td class="text-center">
                        <span v-html="htmlFormula(s.formula)"></span>
                      </td>
                      <td class="text-center">
                        {{ round(s.massFraction * 100, 3) }}
                      </td>
                      <td class="text-center">
                        {{ round(s.volumeFraction * 100, 3) }}
                      </td>
                      <td class="text-center">
                        {{ round(s.moleFraction * 100, 3) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center">Sum</td>
                      <td class="text-center">
                        {{ round(sumMassFractionsWet * 100, 3) }}
                      </td>
                      <td class="text-center">
                        {{ round(sumVolumeFractionsWet * 100, 3) }}
                      </td>
                      <td class="text-center">
                        {{ round(sumMoleFractionsWet * 100, 3) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <MixtureFractionsPieChart
                :fractions="massFractionsWet"
                title="Mass Fractions [%mass]"
                :minimal="true"
                ref="ambientMassFractionsPieChart"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <MixtureFractionsPieChart
                :fractions="volumeFractionsWet"
                title="Volume Fractions [%vol]"
                :minimal="true"
                ref="ambientVolumeFractionsPieChart"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <MixtureFractionsPieChart
                :fractions="moleFractionsWet"
                title="Mole Fractions [%mol]"
                :minimal="true"
                ref="ambientMoleFractionsPieChart"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Flue Gas</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels v-model="flueGasPanels" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3>Flue Gas Species</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <SpeciesIterator
                  :species="flueGas.species"
                  :materials="materials"
                  :readonly="false"
                  :noFractions="true"
                  @addSpecies="addFlueGasSpecies"
                  @deleteSpecies="deleteFlueGasSpecies"
                  ref="speciesIterator"
                />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      v-on="on"
                      class="info mx-2 my-2"
                      @click="resetFlueGasSpecies"
                      depressed
                      text
                    >
                      <v-icon>
                        mdi-backup-restore
                      </v-icon>
                    </v-btn>
                  </template>
                  <span> Reset to standard species</span>
                </v-tooltip>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3>NO<sub>x</sub></h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-numeric-text-field-unit
                      v-model="projectInfo.NOxRate"
                      :settings="settings.NOxRate"
                    >
                    </v-numeric-text-field-unit>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

import UnitConverter from '@/plugins/unitconverter';
import MixtureFractionsPieChart from '@/components/charts/MixtureFractionsPieChart';
import SpeciesIterator from '@/components/mixtures/SpeciesIterator';

export default {
  name: 'General',
  data() {
    return {
      menuDesignDate: false,
      menuRevisionDate: false,
      panels: [0, 1, 2],
      flueGasPanels: [0, 1],
      requiredRule: value => !!value || 'Field is required',
      settings: {
        pressure: {
          label: 'Pressure',
          type: UnitConverter.types.PRESSURE,
          min: 0.5e5,
          max: 10e5,
          baseUnit: 'Pa',
          unit: 'bar',
          precision: 2,
          units: ['Pa', 'bar', 'mbar', 'atm'],
          precisions: [0, 3, 1, 3],
        },
        temperature: {
          label: 'Temperature',
          type: UnitConverter.types.TEMPERATURE,
          min: 0,
          max: null,
          baseUnit: 'K',
          unit: '°C',
          precision: 2,
          units: ['°C', 'K', '°F'],
          precisions: [2, 2, 2],
        },
        phi: {
          label: 'Relative Humidity',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: 1,
          baseUnit: '',
          unit: '%',
          precision: 2,
          units: ['%'],
          precisions: [2],
        },
        NOxRate: {
          label: 'NOx Rate (%mass of N in Fuel)',
          type: UnitConverter.types.UNITLESS,
          min: 0,
          max: 1,
          baseUnit: '',
          unit: '%',
          precision: 2,
          units: ['%'],
          precisions: [2],
        },
      },
      // inputFractions: [
      //   { name: 'Mass Fraction', unit: '%mass' },
      //   { name: 'Volume Fraction', unit: '%vol' },
      //   { name: 'Mole Fraction', unit: '%mol' },
      // ],
    };
  },
  components: {
    MixtureFractionsPieChart,
    SpeciesIterator,
  },
  methods: {
    ...mapActions('materials', ['getMaterials']),
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    round(value, precision) {
      return _.round(value, precision);
    },
    changeInputFraction(fraction) {
      var index = this.inputFractions.findIndex(
        item => item.name === fraction.name,
      );
      this.inputFraction = this.inputFractions[index];
    },
    getFraction(s) {
      var key = _.camelCase(this.inputFraction.name);
      return this.round(s[key] * 100, 4);
    },
    changeFraction(newValue, s) {
      if (!isNaN(parseFloat(newValue))) {
        var key = _.camelCase(this.inputFraction.name);
        s[key] = parseFloat(newValue) / 100;
        this.getAmbientComposition();
      }
    },
    getAmbientComposition() {
      this.$store
        .dispatch('mixtures/getAmbientComposition', this.ambient)
        .then(response => {
          Object.assign(this.ambient, response.data);
          this.$store.dispatch('projects/setUpToDate', false);
          this.$store.dispatch('mixtures/updateCoupledMixtures');
        })
        .catch(error => console.log(error));
    },
    addFlueGasSpecies(newSpecies) {
      newSpecies = JSON.parse(JSON.stringify(newSpecies));
      Array.isArray(newSpecies)
        ? this.flueGas.species.push(...newSpecies)
        : this.flueGas.species.push(newSpecies);
      this.$store.dispatch('projects/setUpToDate', false);
    },
    deleteFlueGasSpecies(speciesToDelete) {
      this.flueGas.species = this.flueGas.species.filter(
        species => species.publicId != speciesToDelete.publicId,
      );
      this.$store.dispatch('projects/setUpToDate', false);
    },
    resetFlueGasSpecies() {
      if (
        confirm(
          'Are you sure you want to reset the flue gas species?',
        )
      ) {
        this.$store.dispatch('mixtures/initFlueGas');
        this.$store.getters[
          'projects/currentProject'
        ].flueGas = this.flueGas; // reference to project
        this.$store.dispatch('projects/setUpToDate', false);
      }
    },
  },
  computed: {
    ...mapGetters('projects', ['projectInfo', 'emptyProject']),
    ...mapGetters('mixtures', [
      'flueGas',
      'ambient',
      'inputFractions',
    ]),
    ...mapGetters('materials', ['materials', 'htmlFormula']),
    designDate() {
      return this.formatDate(this.projectInfo.designDate);
    },
    revisionDate() {
      return this.formatDate(this.projectInfo.revisionDate);
    },
    inputFraction: {
      get: function() {
        return this.ambient.inputFraction;
      },
      set: function(newValue) {
        this.ambient.inputFraction = newValue;
      },
    },
    massFractionsWet() {
      return this.ambient.wet.species.map(s => ({
        formula: s.formula,
        fraction: s.massFraction,
        phase: s.phase,
      }));
    },
    volumeFractionsWet() {
      return this.ambient.wet.species.map(s => ({
        formula: s.formula,
        fraction: s.volumeFraction,
        phase: s.phase,
      }));
    },
    moleFractionsWet() {
      return this.ambient.wet.species.map(s => ({
        formula: s.formula,
        fraction: s.moleFraction,
        phase: s.phase,
      }));
    },
    sumMassFractionsWet() {
      return this.ambient.wet.species.reduce(
        (pv, cv) => pv + cv.massFraction,
        0,
      );
    },
    sumVolumeFractionsWet() {
      return this.ambient.wet.species.reduce(
        (pv, cv) => pv + cv.volumeFraction,
        0,
      );
    },
    sumMoleFractionsWet() {
      return this.ambient.wet.species.reduce(
        (pv, cv) => pv + cv.moleFraction,
        0,
      );
    },
  },
  created() {
    // this.$store.dispatch('getMaterials').then(response => {
    //   this.getAmbientComposition();
    // });
  },
};
</script>

<style></style>
